<template>
  <div>
    <transition
      appear
      name="fade-slide"
    >
      <header-block-tv />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <next-generation-tv />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <innovative />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <generation />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <video-platforms />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <control-method-tv />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <analytics />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <bundles-tv />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <cord />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <limited-time />
    </transition>
    <template v-if="$route.name === 'tvPage'">
      <transition
        appear
        name="fade-slide"
      >
        <questions-tv />
      </transition>
    </template>
    <template v-else>
      <transition
        appear
        name="fade-slide"
      >
        <hubspotForm3
          :appendForm="true"
        />
      </transition>
      <!--      <amoBtn />-->
    </template>
  </div>
</template>

<script>
import headerBlockTv from '@/components/headerBlockTv';
import nextGenerationTv from '@/components/nextGenerationTv';
import generation from '@/components/generation';
import videoPlatforms from '@/components/videoPlatforms';
import controlMethodTv from '@/components/controlMethodTv';
import analytics from '@/components/analytics';
import bundlesTv from '@/components/bundlesTv';
import cord from '@/components/cord';
import limitedTime from '@/components/limitedTime';
import questionsTv from '@/components/questionsTv';
import innovative from '@/components/innovative';
import hubspotForm3 from '@/components/hubspotForm3';
// import amoBtn from '@/components/amoBtn';

export default {
  name: 'TvPage',
  components: {
    headerBlockTv,
    nextGenerationTv,
    generation,
    videoPlatforms,
    controlMethodTv,
    analytics,
    bundlesTv,
    cord,
    limitedTime,
    questionsTv,
    innovative,
    hubspotForm3,
    // amoBtn,
  },
  beforeMount() {
    document.querySelector('body').classList.add('body_tv');
  },
  beforeDestroy() {
    document.querySelector('body').classList.remove('body_tv');
  },
};
</script>

<style lang="scss">
  .body_tv {
    min-width: 1300px;
  }
</style>
