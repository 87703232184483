<template>
  <div class="questions-tv">
    <div class="container">
      <h2
        class="questions-tv__title"
      >
        Frequently Asked Questions
      </h2>
      <transition
        name="fade-block"
        mode="out-in"
        appear
      >
        <div class="faq-list">
          <div class="faq-area">
            <transition-group
              v-for="(item, index) in faq"
              :key="item.answer"
              name="fade-block"
              appear
            >
              <div
                :key="index"
                class="faq-item"
                :class="[{'faq-item_active': toggleActive === index}, {'faq-item_last': item.lastElem}]"
                @click="toggle(item, index, $event)"
              >
                <p
                  class="faq__question"
                  :class="{'faq__question_active': toggleActive === index}"
                >
                  {{ item.question }}
                </p>
                <transition
                  name="fade-el"
                  mode="out-in"
                >
                  <div
                    v-if="toggleActive === index"
                    class="faq__answer"
                    v-html="item.answer"
                  >
                  </div>
                </transition>
              </div>
            </transition-group>
          </div>
        </div>
      </transition>
      <transition
        name="fade-block"
        mode="out-in"
        appear
      >
        <div
          v-if="addFaq"
          class="faq-list"
        >
          <div class="faq-area">
            <div
              v-for="(item, index) in faqSecond"
              :key="index"
              class="faq-item"
              :class="{'faq-item_active': toggleActiveSecond === index}"
              @click="toggleSecond($event, index)"
            >
              <p
                class="faq__question"
                :class="{'faq__question_active': toggleActiveSecond === index}"
              >
                {{ item.question }}
              </p>
              <transition
                name="fade-block"
                mode="out-in"
              >
                <div
                  v-if="toggleActiveSecond === index"
                  class="faq__answer"
                  v-html="item.answer"
                >
                </div>
              </transition>
            </div>
          </div>
        </div>
      </transition>
      <button
        class="questions-tv__btn"
        @click.prevent="!addFaq ? addQuesstions() : removeQuesstions()"
      >
        <template v-if="!addFaq">
          Read more
        </template>
        <template v-if="addFaq">
          Read less
        </template>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionsTv',
  data() {
    return {
      addFaq: false,
      faq: [
        {
          question: 'Can we just buy a TV app?',
          answer: '<p>Yes, you can use our platform as all-in-one or unbundled. On our pricing page, you can select just TV app package, but if you add Mobile app or Website, your price will be reduced significantly. </p>',
        },
        {
          question: 'Do we have to get all platforms, e.g. ROKU, Apple TV, etc, or we can just get Apple TV?',
          answer: '<p>On our pricing page, you can uncheck unwanted platforms but your pricing will not change.</p>',
        },
        {
          question: 'Are you publishing TV apps under our church’s or ministry name?',
          answer: '<p>Yes, correct, your TV apps will be published under your Developer Certificate i.e. under your personal or your organizations’ account (we will help you obtain developer certificates). If you don’t have accounts for Amazon Fire TV, ROKU and Google TV, we’ll be able to publish your apps under our certificate. People will be able to find your TV apps by searching by your organization name or keywords that you’ll provide during the registration process.</p>',
        },
        {
          question: 'How much time does it take to get TV apps live?',
          answer: '<p>It will depend on your responsiveness, but if your Apple Developer Certificate for Apple TV is ready, we can have your apps ready and submitted in less than 10 business days. The approval process with the App Stores usually takes from 5 to 14 business days, ROKU might take a little bit longer.</p>',
        },
        {
          question: 'What are the requirements to publish TV apps?',
          answer: '<p>In order to publish Apple TV app, Apple requires your own developer certificate (we can help you obtain it). Other TV apps we can publish under our corporate certificate or if you have those ready, we can publish under your own accounts.</p>',
        },
        {
          question: 'Is there a contract for this package?',
          answer: '<p>Yes, due to fact that we waive all setup fees ($1,000) and that it takes a lot of time to get your apps ready and updated later, we require a 2-year contract for TV apps. To opt-out, you will need to <a href="mailto:sales@impactfactors.net">contact us</a> 30 days prior to your contract expiration date.</p>',
        },
        {
          question: 'Is there a bandwidth or storage limit for TV app package?',
          answer: '<p>There is no limit on bandwidth although it is important on the pricing page to select proper organizations’ size. This package includes 50Gb of storage. Each additional 100Gb will cost only $20/month with unlimited bandwidth.</p>',
        },
        {
          question: 'Can you help to create a design for our TV app?',
          answer: '<p>There are two options to design your TV app:</p><ol><li> We can help you with our template-based designs (no charge, we’ll do it as a bonus for you ;)</li><li>You can order custom design ($250 one-time fee) and we will get in touch with you and will work with you on your custom icon and splash screen design. If you have more specific requests, please <a href="mailto:sales@impactfactors.net">contact us</a>.</li></ol>',
          lastElem: true,
        },
      ],
      faqSecond: [
        {
          question: 'Can you publish to iTunes Podcasts?',
          answer: '<p>Yes.</p>',
        },
        {
          question: 'Can you publish to Google Podcasts as well?',
          answer: '<p>We will have integration with Google Podcasts ready soon. We will announce it and you’ll be able to add it to your package once it’s ready. </p>',
        },
        {
          question: 'Can you customize our TV apps?',
          answer: '<p>Please <a href="mailto:sales@impactfactors.net">contact us</a> and let us know about your preferences.</p>',
        },
        {
          question: 'What about live-streaming? How can we use our live-streaming provider with your TV apps?',
          answer: '<p>If you want to use our native live-streaming module, you will need to add in CMS your streaming link in .m3u8 or .rtsp format. Your live-streaming provider will be able to provide you with the streaming link in this format. This way you will get the best, native live-streaming experience.</p>',
        },
        {
          question: 'I have more questions; how can I get in touch with your company?',
          answer: '<p>For any other questions, please <a href="mailto:sales@impactfactors.net">contact us</a></p>',
        },
      ],
      toggleActive: false,
      toggleActiveSecond: false,
    };
  },
  methods: {
    toggle(item, index, e) {
      if (e.target.tagName === 'A' || e.target.tagName === 'a') {
        this.toggleActive = true;
      }
      if (this.toggleActive === index) {
        this.toggleActive = false;
      } else {
        this.toggleActive = index;
      }

      if (item.lastElem) {
        item.lastElem = false;
        this.addFaq = true;
      }
    },
    toggleSecond(e, index) {
      if (e.target.tagName === 'A' || e.target.tagName === 'a') {
        this.toggleActiveSecond = true;
      }
      if (this.toggleActiveSecond === index) {
        this.toggleActiveSecond = false;
      } else {
        this.toggleActiveSecond = index;
      }
    },
    addQuesstions() {
      this.addFaq = !this.addFaq;
      this.faq[7].lastElem = false;
    },
    removeQuesstions() {
      this.addFaq = !this.addFaq;
    },
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';
  @import '../assets/sass/utils/transition';

 .questions-tv {
    background: $color-white;
    padding-top: 56px;
    padding-bottom: 83px;
    position: relative;

   &__title {
     margin-bottom: 43px;
   }

   &__btn {
     display: flex;
     justify-content: center;
     width: 100%;
     color: $color-royal-blue-second;
     font-size: 18px;
     cursor: pointer;
     margin-top: 30px;
     letter-spacing: -.6px;
     position: relative;
     left: -1px;
     transition: opacity .3s ease ;

     &:hover {
       opacity: .6;
     }
   }
 }

 .faq {

   &-list {
     margin: 0 -15px;
   }

   &-item {
     box-shadow: 0 1px 3px rgba(48, 49, 51, .2);
     border-radius: 3px;
     margin-bottom: 8px;
     cursor: pointer;
     padding-left: 24px;
     padding-right: 100px;
     border: 1px solid transparent;
     box-sizing: border-box;
     transition: border-color .3s ease, height .3s ease, min-height .3s ease, transform .25s ease-in, box-shadow .25s ease-in;

     &:hover {
       transform: translateY(-4px);
       box-shadow: 2px 3px 10px rgba(48, 49, 51, .2);
     }

     &_last {
       opacity: .2;
       box-shadow: none;
     }

     &_def {
       opacity: 1;
       box-shadow: 0 1px 3px rgba(48, 49, 51, .2);
     }

     &_active {
       border-color: $color-royal-blue-second;
     }
   }

   &__question {
     display: flex;
     align-items: center;
     min-height: 60px;
     width: 100%;
     max-width: 100%;
     background: $color-white;
     font-family: $font-global-bold, sans-serif;
     font-size: 18px;
     line-height: 30px;
     position: relative;

     &:before {
       content: "\E804";
       font-family: $icon-font;
       position: absolute;
       right: -81px;
       top: 18px;
       color: $color-moody-blue;
       font-size: 12px;
       transition: transform .15s ease;
       transform: rotate(90deg);
     }

     &_active {
       &:before {
         transform: rotate(0);
       }
     }
   }

   &__answer {
     display: flex;
     justify-content: center;
     min-height: 50px;
     width: 100%;
     max-width: 100%;
     background: $color-white;
     font-size: 18px;
     line-height: 30px;
     padding-bottom: 22px;
     flex-direction: column;

     a {
       color: $color-black;
       display: inline-block;
       margin-left: 3px;
       font-family: $font-global-medium, sans-serif;
       transition: opacity .3s ease;

       &:hover, &:focus {
         opacity: .6;
       }
     }

     ul {
       list-style-type: lower-latin;
       padding-left: 42px;
     }

     ol {
       list-style-type: decimal;
       padding-left: 42px;

       span {
         font-family: $font-global-medium, sans-serif;
         font-style: italic;
       }

       li {
         /*margin-bottom: 20px;*/

         &:last-child {
           margin-bottom: 0;
         }
       }
     }
   }
 }

  @media (max-width: 767px) {
    .faq {
      &-list {
        margin: 0 15px;
      }
    }
  }
  @media (max-width: 480px) {
    .questions-tv {
      padding-top: 39px;
      padding-bottom: 44px;

      &__title {
        margin-bottom: 32px;
      }
    }

    .faq {
      &-list {
        margin: 0;
      }

      &-item {
        padding-left: 16px;
        margin-bottom: 7px;
      }

      &__answer {
        font-size: 12px;
        line-height: 17px;
      }

      &__question {
        font-size: 13px;
        line-height: 16px;
        min-height: 47px;
      }
    }
  }
</style>
