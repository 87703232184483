<template>
  <div class="video-platforms">
    <div class="container">
      <h2 class="video-platforms__title">
        <span>
          Audiences are moving their attention away from traditional
        </span>
        <span>television to TV apps and digital video platforms</span>
      </h2>
    </div>
    <div class="video-platforms-list">
      <div class="video-platforms-row">
        <div class="video-platforms-item video-platforms-item_first">
          <div class="video-platforms-item__img">
            <img
              src="../assets/images/video-platforms__block-1.jpg"
              alt=""
            >
          </div>
          <div class="video-platforms-item-text">
            <div class="video-platforms-item-text__desc">
              Consumers are ditching cable TV en masse
            </div>
            <div class="video-platforms-item-text__label">
              - Research firm eMarketer
            </div>
          </div>
        </div>
        <div class="video-platforms__img video-platforms__img_first">
          <img
            src="../assets/images/video-platforms__img-1.png"
            alt=""
          >
        </div>
        <div class="video-platforms-item video-platforms-item_second">
          <div class="video-platforms-item__img">
            <img
              src="../assets/images/video-platforms__block-2.jpg"
              alt=""
            >
          </div>
          <div class="video-platforms-item-text">
            <div class="video-platforms-item-text__desc">
              1 in 3 US adults own a connected TV or streaming device
            </div>
            <div class="video-platforms-item-text__label">
              - ROKU
            </div>
          </div>
        </div>
      </div>
      <div class="video-platforms-row">
        <div class="video-platforms__img video-platforms__img_second">
          <img
            src="../assets/images/video-platforms__img-2.png"
            alt=""
          >
        </div>
        <div class="video-platforms-item video-platforms-item_third">
          <div class="video-platforms-item__img">
            <img
              src="../assets/images/video-platforms__block-3.jpg"
              alt=""
            >
          </div>
          <div class="video-platforms-item-text">
            <div class="video-platforms-item-text__desc">
              Cord cutting is accelerating well beyond current expectations
            </div>
            <div class="video-platforms-item-text__label">
              - ROKU, 2018
            </div>
          </div>
        </div>
        <div class="video-platforms__img video-platforms__img_third">
          <img
            src="../assets/images/video-platforms__img-3.png"
            alt=""
          >
        </div>
      </div>
      <div class="video-platforms-row">
        <div class="video-platforms-item video-platforms-item_fourth">
          <div class="video-platforms-item__img">
            <img
              src="../assets/images/video-platforms__block-4.jpg"
              alt=""
            >
          </div>
          <div class="video-platforms-item-text">
            <div class="video-platforms-item-text__desc">
              61% of U.S. millennial heads of household are both pay TV and OTT subscribers
            </div>
            <div class="video-platforms-item-text__label">
              - A survey by Parks Associates
            </div>
          </div>
        </div>
        <div class="video-platforms-item video-platforms-item_fifth">
          <div class="video-platforms-item__img">
            <img
              src="../assets/images/video-platforms__block-5.jpg"
              alt=""
            >
          </div>
          <div class="video-platforms-item-text">
            <div class="video-platforms-item-text__desc">
              <span>Retaining millennials has been a continuing challenge for cable TV operators.</span>
              “Younger audiences continue to switch to either exclusively watching OTT video or watching
              them in combination with free-TV options”
            </div>
            <div class="video-platforms-item-text__label">
              …said Chris Bendtsen at eMarketer
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoPlatforms',
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .video-platforms {
    padding-bottom: 85px;
    padding-top: 80px;
    position: relative;
    overflow: hidden;
    background: $color-mine-shaft-dark;

    &__title {
      color: $color-alto;
      font-size: 37px;
      line-height: 48px;
      margin-bottom: 69px;
    }

    &-row {
      display: flex;
      justify-content: space-between;
    }

    &-list {
      max-width: 1180px;
      width: 100%;
      margin: auto;
    }

    &__img {
      position: relative;
      opacity: .5;

      &_first {
        top: 57px;
        left: 4px;
        z-index: 5;
      }

      &_second {
        top: 64px;
        left: 30px;
      }

      &_third {
        left: -32px;
        top: 69px;
      }
    }

    &-item {
      position: relative;

      &-text {
        position: absolute;
        top: 47px;
        left: 55px;

        &__desc {
          font-size: 18px;
          line-height: 30px;
          font-family: $font-global-bold, sans-serif;
          margin-bottom: 15px;
          color: $color-apricot-peach;

          span {
            font-family: $font-global, sans-serif;
          }
        }

        &__label {
          font-size: 14px;
          line-height: 18px;
          font-family: $font-global, sans-serif;
          text-align: right;
          color: $color-jaffa;
          position: relative;

          span {
            display: block;
          }
        }
      }

      &_first {
        left: 16px;
      }

      &_second {
        top: 26px;
        left: 14px;

        .video-platforms-item-text {
          top: 36px;
          left: 39px;

          &__desc {
            max-width: 320px;
            color: $color-blue-chalk;
          }

          &__label {
            color: $color-heliotrope;
            left: 32px;
            top: -14px;
          }
        }
      }

      &_third {
        margin-top: 15px;

        .video-platforms-item-text {
          top: 38px;
          left: 37px;

          &__desc {
            margin-bottom: 34px;
            color: $color-snow-flurry;
          }

          &__label {
            display: block;
            margin-right: 39px;
            color: $color-green-yellow;
          }
        }
      }

      &_fourth {
        margin-top: 18px;
        left: -15px;

        .video-platforms-item-text {
          top: 40px;
          left: 36px;

          &__desc {
            color: $color-tropical-blue;
          }

          &__label {
            color: $color-cornflower-blue;
            display: block;
            margin-right: 37px;
          }
        }
      }

      &_fifth {
        margin-top: 45px;
        left: 14px;

        .video-platforms-item-text {
          top: 51px;
          left: 51px;

          &__desc {
            max-width: 600px;
            color: $color-alto;
          }

          &__label {
            color: $color-alto;
            display: block;
            margin-right: 29px;
          }
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
