<template>
  <div class="generation">
    <div class="container">
      <div class="generation-content">
        <h2 class="generation__title">
          <span>Streaming Everywhere. The New Generation of TV</span>
        </h2>
        <div class="generation__desc description">
          Make all your sermons, videos, photos, music and live streams available to millions of homes around the world
        </div>
        <div class="generation-tabs-bar">
          <div
            id="gallery-top"
            class="tabs__wrapper tabs__wrapper--overflow"
          >
            <swiper
              id="generation-tabs"
              ref="swiperThumbs"
              :options="swiperOptionThumbs"
              class="generation-tabs"
            >
              <swiper-slide
                v-for="(item, index) in designSiteTabs"
                :key="index"
                class="generation-tabs__item swiper-lazy"
                :class="{'generation-tabs__item_active': activeSlideThumbs === index}"
              >
                {{ item.tabName }}
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div
          id="generation-box gallery-thumbs"
          class="generation-box wow fadeIn"
          data-wow-duration="2s"
        >
          <div class="generation-box__img">
            <img
              src="../assets/images/generation__container.png"
              alt=""
            >
          </div>
          <swiper
            ref="swiperTop"
            :options="swiperOptionTop"
          >
            <swiper-slide
              v-for="(item, index) in designSiteTabsContent"
              :key="item.id"
              class="generation-box__slider"
            >
              <img
                class="generation-box-item__img"
                :src="require('../assets/images/' + item.img + '.png')"
                alt=""
                :class="[
                  {'generation-tabs__item_third': index === 2},
                  {'generation-tabs__item_fifth': index === 4}
                ]"
              >
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  name: 'Generation',
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      windowOldScrollPosition: 0,
      showContentImgScrollDown: true,
      showContentImgScrollUp: true,
      activeSlideThumbs: 0,
      sliderPageIndex: 1,
      swiperOptionTop: {
        slidesPerView: 1,
        loopedSlides: 5,
        longSwipesMs: 0,
        allowTouchMove: false,
        longSwipesRatio: 0,
        // autoplay: true,
        delay: 5000,
        effect: 'fade',
        speed: 1150,
        on: {
          slideChange: this.slideChangeSwiper,
        },
      },
      swiperOptionThumbs: {
        slidesPerView: 5,
        touchRatio: 0.2,
        loopedSlides: 5,
        slideToClickedSlide: false,
        on: {
          click: this.clickSwiper,
        },
        breakpoints: {
          991: {
            slidesPerView: 3,
          },
          600: {
            slidesPerView: 2,
          },
          480: {
            slidesPerView: 3,
          },
          450: {
            slidesPerView: 2,
          },
        },
      },
      activeItemId: 0,
      designSiteTabs: [
        {
          tabName: 'Sermons',
        },
        {
          tabName: 'Live',
        },
        {
          tabName: 'Photos',
        },
        {
          tabName: 'Music',
        },
        {
          tabName: 'Your Own App Branding ',
        },
      ],
      designSiteTabsContent: [
        {
          id: 1,
          img: 'generation__img-1',
        },
        {
          id: 2,
          img: 'generation__img-2',
        },
        {
          id: 3,
          img: 'generation__img-3',
        },
        {
          id: 4,
          img: 'generation__img-4',
        },
        {
          id: 5,
          img: 'generation__img-5',
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('scroll', this.setWindowScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.setWindowScroll);
  },
  methods: {
    setWindowScroll() {
      this.$nextTick(() => {
        // let slideNumberTwo = document.getElementById('slideNumberTwo')
        const galleryTop = document.getElementById('gallery-top');
        const galleryThumbs = document.getElementById('gallery-thumbs');
        let scrollDirection;
        if (this.windowOldScrollPosition <= window.scrollY) {
          scrollDirection = 'down';
        } else {
          scrollDirection = 'up';
        }
        if (scrollDirection === 'down') {
          if ((window.innerHeight >= galleryTop.getBoundingClientRect().y)) {
            if (this.showContentImgScrollDown) {
              if (this.$refs.swiperThumbs !== undefined && this.$refs.swiperTop) {
                this.activeSlideThumbs = 0;
                this.sliderPageIndex = 1;
                this.$refs.swiperThumbs.swiper.slideTo(this.activeSlideThumbs, 0);
                this.$refs.swiperTop.swiper.slideTo(this.activeSlideThumbs, 0);
                this.$refs.swiperTop.swiper.autoplay.start();
                this.showContentImgScrollDown = false;
              }
            }
          } else if (!this.showContentImgScrollDown) {
            this.showContentImgScrollDown = true;
          }
        } else if ((galleryThumbs.getBoundingClientRect().bottom > 0)) {
          if (this.showContentImgScrollUp) {
            if (this.$refs.swiperThumbs !== undefined && this.$refs.swiperTop) {
              this.activeSlideThumbs = 0;
              this.sliderPageIndex = 1;
              this.$refs.swiperThumbs.swiper.slideTo(this.activeSlideThumbs, 0);
              this.$refs.swiperTop.swiper.slideTo(this.activeSlideThumbs, 0);
              this.$refs.swiperTop.swiper.autoplay.start();
              this.showContentImgScrollUp = false;
            }
          }
        } else if (!this.showContentImgScrollUp) {
          this.showContentImgScrollUp = true;
        }
        this.windowOldScrollPosition = window.scrollY;
      });
    },
    slideChangeSwiper() {
      this.activeSlideThumbs = this.$refs.swiperTop.swiper.activeIndex;
      if (this.activeSlideThumbs >= this.$refs.swiperThumbs.swiper.params.slidesPerView * this.sliderPageIndex) {
        this.$refs.swiperThumbs.swiper.slideTo(this.activeSlideThumbs);
        this.sliderPageIndex++;
      } else if (this.activeSlideThumbs === 0) {
        this.$refs.swiperThumbs.swiper.slideTo(this.activeSlideThumbs);
        this.sliderPageIndex = 1;
      }
      const sliderImg = document.getElementsByClassName('generation-box-item__img');
      for (let i = 0; i < sliderImg.length; i++) {
        let srcContentImg = sliderImg[i].getAttribute('src').split('?')[0];
        srcContentImg += `?${Math.random()}`;
        sliderImg[i].setAttribute('src', srcContentImg);
      }
    },
    clickSwiper() {
      this.activeSlideThumbs = this.$refs.swiperThumbs.swiper.clickedIndex;
      this.$refs.swiperTop.swiper.slideTo(this.activeSlideThumbs);
      if (this.activeSlideThumbs > this.$refs.swiperThumbs.swiper.params.slidesPerView) {
        const indexSlidePage = Math.floor(this.activeSlideThumbs / this.$refs.swiperThumbs.swiper.params.slidesPerView);
        this.sliderPageIndex = indexSlidePage;
      }
      // this.activeIndexSlidePhoto = this.activeSlideThumbs
    },
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .generation {
    padding-bottom: 96px;
    background: $color-white;
    position: relative;
    overflow: hidden;
    background: url(../assets/images/generation__bg.png) no-repeat center center;
    background-size: cover;
    padding-top: 120px;

    &-content {
      margin: 0 -15px;
    }

    &__title {
      margin-bottom: 9px;

      span {
        display: block;
      }
    }

    &__desc {
      margin-bottom: 46px;

      span {
        display: block;
      }
    }

    .ps-container {
      max-width: 100%;
      width: 100%;
      margin: auto;
      margin-bottom: 40px;
    }

    .ps.ps--active-x>.ps__scrollbar-x-rail {
      display: block !important;
      opacity: 1 !important;
      height: 1px;
      background: $color-alto;
    }

    .ps>.ps__scrollbar-x-rail>.ps__scrollbar-x {
      background: transparent;
    }

    .ps__scrollbar-x {
      bottom: 0!important;
      height: 1px!important;
    }

    .ps.ps--active-y>.ps__scrollbar-y-rail {
      display: none !important;
    }

    &-tabs {
      display: flex;
      height: 100%;
      width: 100%;
      position: absolute;
      padding: 0;
      top: 0;
      align-items: center;
      list-style: none;
      will-change: transform;
      transition: transform .6s cubic-bezier(.86, 0, .07, 1);

      &__item {
        height: 100%;
        flex: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 30px 14px;
        font-family: $font-global-medium, sans-serif;
        font-size: 21px;
        white-space: nowrap;
        text-align: center;
        cursor: pointer;
        transition: color .3s ease;
        user-select: none;
        color: $color-jumbo;

        &:hover {
          color: $color-moody-blue;
        }

        &:before {
          content: "";
          position: absolute;
          top: auto;
          bottom: 0;
          left: 0;
          /*right: 0;*/
          z-index: 3;
          /*width: 100%;*/
          width: 0;
          height: 1px;
          background-color: transparent;
          border-radius: 1px;
          transition: all .3s ease-in;
        }

        &_active {
          color: $color-moody-blue;

          &:before {
            background-color: $color-moody-blue !important;
            left: 0;
            width: 100%;
          }
        }
      }

      &-bar {
        width: 100%;
        margin-bottom: 89px;
        position: relative;
        height: 42px;
        z-index: 1;
        border-bottom: 1px solid $color-alto;
        max-width: 1070px;
        margin-left: auto;
        margin-right: auto;

        .tabs__wrapper {
          position: relative;
          overflow: visible;
          height: inherit;
        }
      }
    }

    &-box {
      position: relative;
      max-width: 938px;
      width: 100%;
      /*height: 553px;*/
      margin: auto;
      margin-bottom: 32px;
      /*background: url(../assets/images/generation__container.png) no-repeat center center;*/
      min-height: 553px;
      height: 100%;

      &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      &__slider {
        max-width: 925px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /*margin: auto;*/
        position: relative;
        min-height: 530px;
        height: 100%;
        margin: 0;

        img {
          position: relative;
          left: 8px;
          top: 5px;
          transform: rotate(-0.1deg);
          height: 524px;
        }
      }

      &-item {
        background: $color-mercury;
        display: flex;
        justify-content: center;
        max-width: 1140px;

        &__title {
          line-height: 71px;
          font-size: 68px;
          font-family: $font-global-bold, sans-serif;
        }
      }
    }
  }

  .generation-tabs__item_third {
    height: auto !important;
  }

  .generation-tabs__item_fifth {
    height: 530px !important;
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }

  .swiper-container {
    max-height: 530px;
  }
</style>
