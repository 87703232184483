<template>
  <div class="analytics">
    <div class="container">
      <h2 class="analytics__title">
        Analytics for your TV apps and all your media in one place
      </h2>
      <div class="analytics__img">
        <img
          src="../assets/images/analytics__img.png"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Analytics',
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .analytics {
    padding-top: 80px;
    position: relative;
    overflow: hidden;
    background: $color-gallery-dark;
    background-size: cover;

    &__img {
      text-align: center;
      line-height: 0;
    }

    &__title {
      color: $color-shark;
      font-size: 37px;
      line-height: 48px;
      margin-bottom: 65px;
    }

    .container {
      max-width: 1440px;
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
