<template>
  <div>
    <!--<transition appear :name="$route.name === 'pricingPage' ? 'fade-start': 'fade-end'" mode="out-in">-->
    <FixedHeader :fixed.sync="isFixed">
      <div
        class="header-block-tv"
        :class="{'header-block-tv_fixed': isFixed}"
      >
        <div class="container container_extend">
          <div class="header-block-tv-content">
            <div class="header-block-tv-left">
              <div
                class="logo"
                @click="$router.push({name: 'index'})"
              >
                <img
                  v-if="!isFixed"
                  src="../assets/images/logo.png"
                  alt=""
                >
                <img
                  v-else
                  src="../assets/images/logo-mini.png"
                  alt=""
                >
              </div>
            </div>
            <input
              id="burg"
              type="checkbox"
              class="burger"
            >
            <label
              for="burg"
              class="btn-menu"
            >
              <span class="item"></span>
              <span class="item"></span>
              <span class="item"></span>
            </label>
            <i
              class="header-lock icon-lock"
              @click="showModalAuth = true"
            ></i>
            <div
              class="header-block-tv-right"
              :class="{'header-block-tv-right_pricing': $route.name === 'pricingPage'}"
            >
              <div
                v-if="$route.name !== 'pricingPage'"
                class="offer"
                :style="{width: 565}"
              >
                <template v-if="false">
                  Call for Special Offer! <a href="tel:+1(214) 833-9879">(214) 833-9879</a>
                </template>
                <!--                <div class="offer__ends">-->
                <!--                  This offer ends in:-->
                <!--                </div>-->
                <!--                <countdown class="countdown" deadline="December 20, 2020" />-->
              </div>
              <a
                v-if="$route.name !== 'pricingPage'"
                v-show="false"
                href="#"
                class="header-block-tv-link btn"
                @click.prevent="showModalVideoHeader = true"
              >
                Watch demo
                <i class=""></i>
                <!--                <img src="../assets/images/header__link.png" alt="">-->
              </a>
              <ul
                v-if="$route.name !== 'pricingPage'"
                class="menu"
              >
                <li class="menu-item">
                  <!--                  <a class="menu-item__link" href="">Solutions</a>-->
                  <select-template
                    :settings="selectSiteSettings"
                    @changeSelect="changeSite($event)"
                  />
                </li>
                <!--                <li class="menu-item">-->
                <!--                  <a class="menu-item__link" href="">About</a>-->
                <!--                </li>-->
              </ul>
              <div
                v-if="$route.name !== 'pricingPage'"
                class="actions"
                :class="{'action_index2': $route.name === 'tvPage2'}"
              >
                <template v-if="$route.name !== 'tvPage2'">
                  <button
                    class="btn actions__btn actions__btn_get"
                    @click.prevent="showModalGetStarted = !showModalGetStarted"
                  >
                    Get Started
                  </button>
                  <button
                    class="btn actions__btn actions__btn_try"
                    @click="showModalGetStarted = true"
                  >
                    See Pricing
                  </button>
                </template>
                <button
                  v-else
                  class="btn actions__btn actions__btn_get"
                  :style="{
                    width: 'auto!important',
                    padding: '0 15px'
                  }"
                  @click="scrollToForm()"
                >
                  Schedule a Demo
                </button>
              </div>
              <!--              <div class="lang" v-if="$route.name !== 'pricingPage'">-->
              <!--                <span class="lang__item">-->
              <!--                  En-->
              <!--                  <i class="icon-arrow"></i>-->
              <!--                </span>-->
              <!--              </div>-->
              <!--                <ul class="lang-dropdown">-->
              <!--                <li class="lang-dropdown__item">-->
              <!--                <span>En</span>-->
              <!--                </li>-->
              <!--                <li class="lang-dropdown__item">-->
              <!--                <span>De</span>-->
              <!--                </li>-->
              <!--                <li class="lang-dropdown__item">-->
              <!--                <span>Fr</span>-->
              <!--                </li>-->
              <!--                </ul>-->
              <!--              </div>-->
              <button
                v-if="$route.name !== 'pricingPage' && $route.name !== 'tvPage2'"
                class="btn header-block-tv__login"
                @click="showModalAuth = true"
              >
                <span>Client Login</span>
                <i class="icon-lock"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </FixedHeader>
    <!--</transition>-->
    <transition
      name="fade-modal"
      appear
    >
      <modalsVideo
        v-if="showModalVideoHeader"
        :showModalVideo.sync="showModalVideoHeader"
      >
      </modalsVideo>
    </transition>
    <transition
      name="fade-modal"
      appear
    >
      <modals-new-project
        v-if="showModalNewProject"
      >
      </modals-new-project>
    </transition>
    <transition
      name="animate-unfolding__get-started"
      appear
      mode="in-out"
    >
      <modals-get-started
        v-if="showModalGetStarted"
        :showModalAttendance.sync="showModalAttendance"
      >
      </modals-get-started>
      <modals-attendance
        v-if="showModalAttendance"
        :showModalAttendance.sync="showModalAttendance"
      >
      </modals-attendance>
    </transition>
    <transition
      name="fade-modal"
      appear
    >
      <modals-auth
        v-if="showModalAuth"
        :showModalAuth.sync="showModalAuth"
      >
      </modals-auth>
    </transition>
  </div>
</template>

<script>
import FixedHeader from 'vue-fixed-header';
import Countdown from 'vuejs-countdown';
// import modalsVideo from '@/components/modals/modalsVideo'
// import modalsGetStarted from '@/components/modals/modalsGetStarted'
// import modalsAttendance from '@/components/modals/modalsAttendance'
// import modalsNewProject from '@/components/modals/modalsNewProject'
// import modalsAuth from '@/components/modals/modalsAuth'
import find from 'lodash/find';
// import selectTemplate from '@/components/selectTemplate'
const modalsVideo = () => import('@/components/modals/modalsVideo');
const modalsGetStarted = () => import('@/components/modals/modalsGetStarted');
const modalsAttendance = () => import('@/components/modals/modalsAttendance');
const modalsNewProject = () => import('@/components/modals/modalsNewProject');
const modalsAuth = () => import('@/components/modals/modalsAuth');
const selectTemplate = () => import('@/components/selectTemplate');

export default {
  name: 'HeaderBlockTv',
  components: {
    FixedHeader,
    Countdown,
    modalsVideo,
    modalsGetStarted,
    modalsAttendance,
    modalsNewProject,
    selectTemplate,
    modalsAuth,
  },
  props: ['showModalVideo'],
  data() {
    return {
      showModalAttendance: false,
      isFixed: false,
      showModalAuth: false,
      sites: {
        solution1: {
          key: 'solution1',
          text: 'Website',
          link: '/websites',
          routeName: 'indexPage',
        },
        solution2: {
          key: 'solution2',
          text: 'Mobile App',
          link: '/mobile',
          routeName: 'mobilePage',
        },
        solution3: {
          key: 'solution3',
          text: 'TV App',
          link: '/tv',
          routeName: 'tvPage',
        },
      },
      sites2: {
        solution1: {
          key: 'solution1',
          text: 'Website',
          link: '/websites2',
          routeName: 'indexPage2',
        },
        solution2: {
          key: 'solution2',
          text: 'Mobile App',
          link: '/mobile2',
          routeName: 'mobilePage2',
        },
        solution3: {
          key: 'solution3',
          text: 'TV App',
          link: '/tv2',
          routeName: 'tvPage2',
        },
      },
      siteActive: 'solution1',
    };
  },
  computed: {
    selectSiteSettings() {
      return {
        optionList: this.$route.name === 'tvPage2' ? this.sites2 : this.sites,
        fontFamily: false,
        placeholderText: 'SOLUTIONS',
        selectedKey: '',
        disable: false,
        toTop: false,
        tabindex: 1,
        selectClass: 'select_solution',
        notChange: true,
        inputValueStatic: true,
        readonly: true,
        hoverState: true,
        activeElement: true,
      };
    },
    setSite: {
      get() {
        return this.siteActive;
      },
      set(data) {
        this.siteActive = data;
      },
    },
    showModalNewProject: {
      get() {
        return this.$store.getters.showModalNewProject;
      },
      set(data) {
        this.$store.commit('setShowModalNewProject', data);
      },
    },
    showModalGetStarted: {
      get() {
        return this.$store.getters.showModalGetStarted;
      },
      set(data) {
        this.$store.commit('setShowModalGetStarted', data);
      },
    },
    showModalVideoHeader: {
      get() {
        return this.showModalVideo;
      },
      set(data) {
        this.$emit('update:showModalVideo', data);
      },
    },
  },
  methods: {
    scrollTo(element, to, duration, clear) {
      if (duration === 0) {
        return;
      }
      if (to < 0) to = 0;
      const difference = to - element.scrollY;
      const perTick = difference / duration * 10;
      if (clear) {
        clearTimeout(this.setTimeoutId);
      }
      this.setTimeoutId = setTimeout(() => {
        let newTop = element.scrollY + perTick;
        if (newTop < 0) newTop = 0;
        element.scrollTo({
          top: newTop,
        });
        if (newTop === 0) return false;
        if (element.scrollY === to) return;
        this.scrollTo(element, to, duration - 10);
      }, 10);
    },
    scrollToForm() {
      const anchorBlock = document.getElementById('hubspotFormContainer');
      this.scrollTo(window, anchorBlock.offsetTop, 500, 'clear');
    },
    changeSite(key) {
      const arr = this.$route.name === 'tvPage2' ? this.sites2 : this.sites;
      const el = find(arr, (obj) => obj.key === key);
      // setTimeout(() => {
      const linkA = document.createElement('a');
      linkA.setAttribute('target', '_blank');
      linkA.setAttribute('href', el.link);
      linkA.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      // }, 200)

      // this.$router.push({name: el.link})
      // this.setSite = key
    },
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .header-lock  {
    display: none;
  }
  .burger {
    display: none;
  }

  .header-block-tv {
    display: flex;
    min-height: 104px;
    align-items: center;
    position: absolute;
    z-index: 1000;
    width: 100%;
    top: 0;
    left: 0;
    transition: all .14s ease;

    &__login {
      transition: all .14s ease;
      padding: 0 !important;
      position: relative;
      top: 1px;
      overflow: hidden;
      color: $color-jumbo;
      border-color: $color-jumbo;
      height: 33px;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        background: rgba(255, 255, 255, .5);
        opacity: 0;
        border-radius: 100%;
        transform: scale(1, 1) translate(-50%);
        transform-origin: 50% 50%;
      }

      @keyframes ripple {
        0% {
          transform: scale(0, 0);
          opacity: 1;
        }
        20% {
          transform: scale(25, 25);
          opacity: 1;
        }
        100% {
          opacity: 0;
          transform: scale(40, 40);
        }
      }

      &:focus:not(:active)::after {
        animation: ripple 2s ease-out;
      }

      span {
        position: relative;
        left: -3px;
        top: 1px;
      }

      i {
        position: relative;
        font-size: 18px;
        left: 4px;
        top: -1px;
      }

      &:hover {
        background: $color-white;
        color: $color-royal-blue;
        border-color: $color-white;
      }
    }

    &-link {
      display: none;
    }
    .offer {
      display: none;
    }

    &_fixed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
      background: $color-shark;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .3);
      min-height: 56px;

      .actions__btn_try {
        border-color: $color-moody-blue !important;
        color: $color-alto !important;

        &:hover {
          /*background: $color-white !important;
          color: $color-shark !important;*/
          background: $color-moody-blue !important;
          color: $color-white !important;
        }
      }

      .logo {
        max-width: 32px !important;
        position: relative;
        top: 3px;
      }

      .header-block-tv-content {
        justify-content: flex-start;
      }

      .header-block-tv-left {
        width: 14%;
      }

      .header-block-tv-right {
        justify-content: space-between;
        width: 84.8%;
        top: 0;

        &_pricing {
          justify-content: flex-end;
        }
      }

      .actions {
        margin-right: 0 !important;
        position: relative;
        left: 16px;
        top: -2px;

        &__btn {
          width: 108px !important;
          font-size: 14px !important;
        }
      }

      .countdown {
        color: $color-white;
        display: flex;
        min-width: 400px;

        li {
          display: flex !important;
          align-items: center;
          margin-right: 15px;

          p {
            text-transform: lowercase !important;
          }

          &:after {
            display: none;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        .digit {
          color: $color-white;
          font-family: $font-global-bold, sans-serif;
          line-height: 56px;
          font-size: 32px;
          margin-right: 10px;
        }

        .text {
          font-size: 18px !important;
          color: $color-jumbo;
        }
      }

      .offer {
        display: flex;
        align-items: center;
        position: relative;
        /*top: 2px;*/
        min-width: 565px;
        color: $color-white;
        font-family: $font-global-bold;
        line-height: 56px;
        font-size: 32px;

        a {
          color: inherit;
          text-decoration: none;
          margin-left: 5px;
        }

        &-days, &-hour, &-minutes, &-seconds {
          display: flex;
          align-items: center;
          margin-right: 15px;
        }

        &-seconds {
          margin-right: 0;
        }

        &__ends {
          line-height: 24px;
          font-size: 18px;
          font-family: $font-global-medium, sans-serif;
          color: $color-jumbo;
          margin-right: 20px;
        }

        &__number {
          color: $color-white;
          font-family: $font-global-bold;
          line-height: 56px;
          font-size: 32px;
          margin-right: 10px;
        }

        &__text {
          font-size: 18px;
          color: $color-jumbo;
        }

      }

      .header-block-tv-link {
        position: relative;
        display: flex;
        width: 152px;
        text-decoration: none;
        border-color: $color-moody-blue;
        color: $color-white;
        left: 19px;
        font-size: 16px;
        padding-right: 44px;

        &:hover {
          background: $color-moody-blue;

          &:before {
            background: $color-white;
          }
        }

        &:before {
          content: '\E86B';
          font-family: $icon-font;
          position: absolute;
          top: 6px;
          right: 18px;
          color: $color-shark;
          background: $color-moody-blue;
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 8px;
          cursor: pointer;
          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .3);
          opacity: 1;
          padding-left: 2px;
          transition: all .2s ease-in;
        }
      }

      .header-block-tv__login {
        display: none;
      }

      .lang {
        display: none;
      }

      .menu {
        display: none !important;
      }

      .actions__btn_get {
        height: 32px !important;

        &:hover, :focus {
          border-color: transparent !important;
          background: $color-white !important;
          color: $color-moody-blue;
          opacity: 1 !important;
        }
      }

    }

    .logo {
      max-width: 215px;
      width: 100%;
      transition: width .14s ease;
      cursor: pointer;

      img {
        /*transition: all .5s ease;*/
      }
    }

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
    }

    &-left {

    }

    &-right {
      display: flex;
      align-items: center;
      position: relative;
      top: -4px;
      padding: 5px 0;
    }

    .menu {
      display: flex;
      /*margin-right: 13px;*/
      margin-top: 12px;

      &-item {
        margin: 0 24px;

        &:first-child {
          a {
            font-family: $font-global-bold, sans-serif;
            color: $color-white;
          }
        }

        &__link {
          color: $color-white;
          text-decoration: none;
          font-size: 16px;
          text-transform: uppercase;
          transition: opacity .14s ease;

          &:hover, &:focus {
            opacity: .6;
          }
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      margin-right: 45px;
      margin-top: 2px;

      &__btn {
        transition: all .14s ease;

        &_get {
          height: 40px;
          background: $color-moody-blue;
          font-family: $font-global-medium, sans-serif;
          font-size: 16px;
          display: inline-block;
          color: $color-white;
          padding: 0 6px;
          border: 1px solid transparent;
          width: 137px;
          transition: opacity .2s ease-in, background .2s ease-in, color .2s ease-in;
          user-select: none;

          &:hover {
            opacity: .8;
          }

          &:active {
            background: $color-martinique;
            opacity: 1;
          }

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            background: rgba(255, 255, 255, .5);
            opacity: 0;
            border-radius: 100%;
            transform: scale(1, 1) translate(-50%);
            transform-origin: 50% 50%;
          }

          @keyframes ripple {
            0% {
              transform: scale(0, 0);
              opacity: 1;
            }
            20% {
              transform: scale(25, 25);
              opacity: 1;
            }
            100% {
              opacity: 0;
              transform: scale(40, 40);
            }
          }

          &:focus:not(:active)::after {
            animation: ripple 1.5s ease-out;
          }
        }

        &_try {
          border-color: $color-moody-blue;
          color: $color-royal-blue;
          width: 116px;
          height: 33px;
          padding-top: 1px;

          &:hover {
            background: $color-moody-blue;
            color: $color-white;
          }
        }

        &:last-child {
          margin-left: 16px;
        }
      }
    }

    /*.lang {
      margin-right: 16px;
      cursor: pointer;
      position: relative;
      top: -1px;

      .icon-arrow {
        font-size: 8px;
        position: relative;
        top: -3px;
        left: 5px;
        color: $color-jumbo;
      }

      &__item {
        font-size: 16px;
        text-transform: uppercase;
        position: relative;
        left: -5px;
        color: $color-shark;
      }
    }*/
  }

  .fade-modal {
    &-enter-active {
      transition: .2s ease-in;
      .modals-video__content {
        animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
    &-leave-active {
      transition: .2s ease-in;

      .modals-video__content {
        animation: scaleDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
    &-enter, &-leave-to {
      opacity: 0;

      .modals-video__content {
        opacity: 0;
        transform: scale(0);
      }
    }
  }
</style>
