<template>
  <div class="control-method-tv">
    <div class="container">
      <h2 class="control-method-tv__title">
        Simplicity. Efficiency. Best-practices
      </h2>
      <div class="control-method-tv__desc description">
        People today aren't just watching media on a single device: they are accessing content
        on multiple devices and platforms. With your own, custom TV app and our powerful platform,
        you can get a full package to reach users wherever they are and gain operational excellence
        and efficiencies to manage your whole infrastructure from one place.
      </div>
      <div class="control-method-tv-content">
        <div class="control-method-tv__img">
          <img
            id="controlMethodContentImg"
            src="../assets/images/old-vs-new.gif"
            alt=""
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ControlMethod',
  data() {
    return {};
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .control-method-tv {
    background: $color-white;
    padding-top: 80px;
    padding-bottom: 124px;
    position: relative;

    &__desc {
      margin-bottom: 132px;
      margin-top: 25px;
    }

    &-content {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin: 0 -15px;
    }

    &__img {
      max-width: 1140px;
      width: 100%;
    }

    /*
    &__vs {
      position: absolute;
      left: 44.8%;
      top: 58%;
      transform: translate(-50%, -50%);
      font-size: 26px;
      text-transform: uppercase;
      font-family: $font-global-bold, sans-serif;
      color: $color-jumbo;
    }

    &-right {
      width: 54%;
      display: flex;
      flex-direction: column;

      .old-way__title {
        position: relative;
        left: 28px;
      }

      .old-way__img {
        position: relative;
        left: 39px;
      }
    }

    &-left {
      width: 46%;
      display: flex;

      .old-way__title {
        position: relative;
        left: -24px;
      }

      .old-way__img {
        position: relative;
        left: -15px;
      }
    }*/
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
