<template>
  <div class="cord">
    <div class="container">
      <h2 class="cord__title">
        Cord Cutters Are Transforming into TV App Subscribers
      </h2>
    </div>
    <div class="cord__img">
      <img
        src="../assets/images/cord__img.png"
        alt=""
      >
    </div>
    <div class="cord-list">
      <div class="cord-row">
        <div class="cord-item cord-item_first">
          <div class="cord-item__text">
            Millions of people use Roku players. Roku player users are
            highly engaged: the average user watches 50 hours per month.
            Collectively, our users streamed more
            than 3 billon hours last year.
          </div>
          <div class="cord-item__label">
            5 Roku sales & player log data from 2008 to 2015
          </div>
        </div>
        <div class="cord-item cord-item_second">
          <div class="cord-item__text">
            53% of U.S. broadband households subscribe to
            both a pay-TV service and at least one OTT video service.
          </div>
          <div class="cord-item__label">
            <span>Parks Associates</span>
            <span>OOT Video & TV Everywhere: Partners, Alternatives and</span>
            Competition
          </div>
        </div>
      </div>
      <div class="cord-row cord-row_second">
        <div class="cord-item cord-item_third">
          <div class="cord-item__text">
            Apple TV – the future of television.
          </div>
          <div class="cord-item__label">
            - Apple Inc.
          </div>
        </div>
      </div>
      <div class="cord-row cord-row_third">
        <div class="cord-item cord-item_fourth">
          <div class="cord-item__text">
            Everyone’s doing it! Streaming is not just
            for young men or millennials. It’s happening
            across all ages…  and accelerating.
          </div>
          <div class="cord-item__label">
            U.S. BB HHs with Internet - Connected CE Device by Age (2012-2015)
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cord',
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .cord {
    padding-bottom: 75px;
    padding-top: 80px;
    position: relative;
    overflow: hidden;
    background: $color-white;
    background-size: cover;

    &__title {
      color: $color-shark;
      margin-bottom: 67px;
    }

    &__img {
      position: absolute;
      transform: translateX(-50%);
      top: 230px;
      left: 51%;
    }

    &-list {
      max-width: 1125px;
      width: 100%;
      margin: auto;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      position: relative;

      &_second {
        justify-content: flex-end;
        margin-top: -65px;
        left: 5px;
      }

      &_third {
        justify-content: center;
        margin-top: 73px;
      }
    }

    &-item {
      width: auto;
      padding: 18px 15px 20px 20px;
      border: 2px solid $color-silver;
      border-radius: 8px;
      position: relative;
      min-width: 300px;
      height: 100%;

      &_first {
        width: 339px;
        margin-top: 89px;
        margin-left: 10px;
      }

      &_second {
        width: 380px;

        .cord-item__text {
          margin-bottom: 13px;
        }
      }

      &_third {
        width: 320px;
      }

      &_fourth {
        width: 705px;

        .cord-item__text {
          margin-bottom: 27px;
        }

        .cord-item__label {
          margin-bottom: 5px;
        }
      }

      &:after {
        content: url('../assets/images/cord-quotes.png');
        position: absolute;
        top: -22px;
        left: -22px;
      }

      &:before {
        content: url('../assets/images/cord-quotes.png');
        position: absolute;
        bottom: -22px;
        right: -22px;
        transform: rotate(180deg);
      }

      &__text {
        font-size: 18px;
        line-height: 30px;
        font-family: $font-global-bold, sans-serif;
        margin-bottom: 15px;
      }

      &__label {
        font-size: 14px;
        line-height: 18px;
        font-family: $font-global, sans-serif;
        text-align: right;
        color: $color-jumbo;
        margin-right: 3px;

        span {
          display: block;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
