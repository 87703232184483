<template>
  <div class="limited-time">
    <div class="container">
      <h2 class="limited-time__title">
        Limited Time Offer
      </h2>
      <div class="limited-time-content">
        <div
          v-for="item in imageLimitedTime"
          :key="item.id"
          class="limited-time__img"
          :class="item.className"
        >
          <img
            :src="require('../assets/images/' + item.img + '.png')"
            alt=""
          >
        </div>
        <span class="limited-time__price"> = $40/month</span>
      </div>
      <button
        class="btn limited-time__btn"
        @click.prevent="showModalGetStarted = !showModalGetStarted"
      >
        Get Started
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LimitedTime',
  data() {
    return {
      imageLimitedTime: [
        {
          id: 0,
          img: 'limited-time__img-1',
        },
        {
          id: 1,
          img: 'limited-time__img-2',
        },
        {
          id: 2,
          img: 'limited-time__img-3',
        },
        {
          id: 3,
          img: 'limited-time__img-4',
        },
        {
          id: 4,
          img: 'limited-time__img-5',
          className: 'limited-time__img_radio',
        },
      ],
    };
  },
  computed: {
    showModalGetStarted: {
      get() {
        return this.$store.getters.showModalGetStarted;
      },
      set(data) {
        this.$store.commit('setShowModalGetStarted', data);
      },
    },
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .limited-time {
    padding-bottom: 63px;
    padding-top: 40px;
    position: relative;
    overflow: hidden;
    background: $color-scooter-dark;

    &__btn {
      margin: auto;
      font-size: 16px;
      color: $color-white;
      background: $color-french-rose;
      width: 169px;
      height: 40px;
      margin-top: 39px;
      transition: all .15s ease-in;

      &:hover {
        opacity: .6;
      }
    }

    &__price {
      color: $color-white;
      font-size: 32px;
      line-height: 32px;
      font-family: $font-global-bold, sans-serif;
      position: relative;
      left: -23px;
    }

    &-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__img {
      margin: 0 32px;
      position: relative;
      display: flex;

      &_radio {
        left: -13px;
        &:after {
          display: none;
        }
      }

      &:after {
        position: absolute;
        content: '+';
        color: $color-white;
        top: 50%;
        transform: translateY(-50%);
        right: -30px;
        font-size: 20px;
      }
    }

    &__title {
      color: $color-white;
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 34px;
    }

  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
